@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,600');

@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@font-face {
    font-family: 'Neo Sans Pro Ultra';
    src: url('/fonts/subset-NeoSansPro-Ultra.eot');
    src: url('/fonts/subset-NeoSansPro-Ultra.eot?#iefix') format('embedded-opentype'),
        url('/fonts/subset-NeoSansPro-Ultra.woff2') format('woff2'),
        url('/fonts/subset-NeoSansPro-Ultra.woff') format('woff'),
        url('/fonts/subset-NeoSansPro-Ultra.ttf') format('truetype'),
        url('/fonts/subset-NeoSansPro-Ultra.svg#NeoSansPro-Ultra') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Neo Sans Pro';
    src: url('/fonts/subset-NeoSansPro-Regular.eot');
    src: url('/fonts/subset-NeoSansPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('/fonts/subset-NeoSansPro-Regular.woff2') format('woff2'),
        url('/fonts/subset-NeoSansPro-Regular.woff') format('woff'),
        url('/fonts/subset-NeoSansPro-Regular.ttf') format('truetype'),
        url('/fonts/subset-NeoSansPro-Regular.svg#NeoSansPro-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Neo Sans Pro';
    src: url('/fonts/subset-NeoSansPro-Bold.eot');
    src: url('/fonts/subset-NeoSansPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/subset-NeoSansPro-Bold.woff2') format('woff2'),
        url('/fonts/subset-NeoSansPro-Bold.woff') format('woff'),
        url('/fonts/subset-NeoSansPro-Bold.ttf') format('truetype'),
        url('/fonts/subset-NeoSansPro-Bold.svg#NeoSansPro-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Neo Sans Pro';
    src: url('/fonts/subset-NeoSansPro-Medium.eot');
    src: url('/fonts/subset-NeoSansPro-Medium.eot?#iefix') format('embedded-opentype'),
        url('/fonts/subset-NeoSansPro-Medium.woff2') format('woff2'),
        url('/fonts/subset-NeoSansPro-Medium.woff') format('woff'),
        url('/fonts/subset-NeoSansPro-Medium.ttf') format('truetype'),
        url('/fonts/subset-NeoSansPro-Medium.svg#NeoSansPro-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Neo Sans Pro';
    src: url('/fonts/subset-NeoSansPro-Light.eot');
    src: url('/fonts/subset-NeoSansPro-Light.eot?#iefix') format('embedded-opentype'),
        url('/fonts/subset-NeoSansPro-Light.woff2') format('woff2'),
        url('/fonts/subset-NeoSansPro-Light.woff') format('woff'),
        url('/fonts/subset-NeoSansPro-Light.ttf') format('truetype'),
        url('/fonts/subset-NeoSansPro-Light.svg#NeoSansPro-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Neo Sans Pro';
    src: url('/fonts/subset-NeoSansPro-LightItalic.eot');
    src: url('/fonts/subset-NeoSansPro-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/subset-NeoSansPro-LightItalic.woff2') format('woff2'),
        url('/fonts/subset-NeoSansPro-LightItalic.woff') format('woff'),
        url('/fonts/subset-NeoSansPro-LightItalic.ttf') format('truetype'),
        url('/fonts/subset-NeoSansPro-LightItalic.svg#NeoSansPro-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Neo Sans Pro';
    src: url('/fonts/subset-NeoSansPro-BoldItalic.eot');
    src: url('/fonts/subset-NeoSansPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/subset-NeoSansPro-BoldItalic.woff2') format('woff2'),
        url('/fonts/subset-NeoSansPro-BoldItalic.woff') format('woff'),
        url('/fonts/subset-NeoSansPro-BoldItalic.ttf') format('truetype'),
        url('/fonts/subset-NeoSansPro-BoldItalic.svg#NeoSansPro-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Neo Sans Pro';
    src: url('/fonts/subset-NeoSansPro-Italic.eot');
    src: url('/fonts/subset-NeoSansPro-Italic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/subset-NeoSansPro-Italic.woff2') format('woff2'),
        url('/fonts/subset-NeoSansPro-Italic.woff') format('woff'),
        url('/fonts/subset-NeoSansPro-Italic.ttf') format('truetype'),
        url('/fonts/subset-NeoSansPro-Italic.svg#NeoSansPro-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Neo Sans Std';
    src: url('/fonts/subset-NeoSansStd-MediumItalic.eot');
    src: url('/fonts/subset-NeoSansStd-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/subset-NeoSansStd-MediumItalic.woff2') format('woff2'),
        url('/fonts/subset-NeoSansStd-MediumItalic.woff') format('woff'),
        url('/fonts/subset-NeoSansStd-MediumItalic.ttf') format('truetype'),
        url('/fonts/subset-NeoSansStd-MediumItalic.svg#NeoSansStd-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'NeoSans';
    src: url('/fonts/subset-NeoSans-LightItalic.eot');
    src: url('/fonts/subset-NeoSans-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/subset-NeoSans-LightItalic.woff2') format('woff2'),
        url('/fonts/subset-NeoSans-LightItalic.woff') format('woff'),
        url('/fonts/subset-NeoSans-LightItalic.ttf') format('truetype'),
        url('/fonts/subset-NeoSans-LightItalic.svg#NeoSans-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Neo Sans Std';
    src: url('/fonts/subset-NeoSansStd-Light.eot');
    src: url('/fonts/subset-NeoSansStd-Light.eot?#iefix') format('embedded-opentype'),
        url('/fonts/subset-NeoSansStd-Light.woff2') format('woff2'),
        url('/fonts/subset-NeoSansStd-Light.woff') format('woff'),
        url('/fonts/subset-NeoSansStd-Light.ttf') format('truetype'),
        url('/fonts/subset-NeoSansStd-Light.svg#NeoSansStd-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'NeoSans';
    src: url('/fonts/subset-NeoSans-Medium.eot');
    src: url('/fonts/subset-NeoSans-Medium.eot?#iefix') format('embedded-opentype'),
        url('/fonts/subset-NeoSans-Medium.woff2') format('woff2'),
        url('/fonts/subset-NeoSans-Medium.woff') format('woff'),
        url('/fonts/subset-NeoSans-Medium.ttf') format('truetype'),
        url('/fonts/subset-NeoSans-Medium.svg#NeoSans-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Neo Sans Std';
    src: url('/fonts/subset-NeoSansStd-Bold.eot');
    src: url('/fonts/subset-NeoSansStd-Bold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/subset-NeoSansStd-Bold.woff2') format('woff2'),
        url('/fonts/subset-NeoSansStd-Bold.woff') format('woff'),
        url('/fonts/subset-NeoSansStd-Bold.ttf') format('truetype'),
        url('/fonts/subset-NeoSansStd-Bold.svg#NeoSansStd-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Neo Sans Std';
    src: url('/fonts/subset-NeoSansStd-Regular.eot');
    src: url('/fonts/subset-NeoSansStd-Regular.eot?#iefix') format('embedded-opentype'),
        url('/fonts/subset-NeoSansStd-Regular.woff2') format('woff2'),
        url('/fonts/subset-NeoSansStd-Regular.woff') format('woff'),
        url('/fonts/subset-NeoSansStd-Regular.ttf') format('truetype'),
        url('/fonts/subset-NeoSansStd-Regular.svg#NeoSansStd-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Neo Sans Std';
    src: url('/fonts/subset-NeoSansStd-BlackItalic.eot');
    src: url('/fonts/subset-NeoSansStd-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/subset-NeoSansStd-BlackItalic.woff2') format('woff2'),
        url('/fonts/subset-NeoSansStd-BlackItalic.woff') format('woff'),
        url('/fonts/subset-NeoSansStd-BlackItalic.ttf') format('truetype'),
        url('/fonts/subset-NeoSansStd-BlackItalic.svg#NeoSansStd-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Neo Sans Std';
    src: url('/fonts/subset-NeoSansStd-Black.eot');
    src: url('/fonts/subset-NeoSansStd-Black.eot?#iefix') format('embedded-opentype'),
        url('/fonts/subset-NeoSansStd-Black.woff2') format('woff2'),
        url('/fonts/subset-NeoSansStd-Black.woff') format('woff'),
        url('/fonts/subset-NeoSansStd-Black.ttf') format('truetype'),
        url('/fonts/subset-NeoSansStd-Black.svg#NeoSansStd-Black') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Neo Sans Std';
    src: url('/fonts/subset-NeoSansStd-Medium.eot');
    src: url('/fonts/subset-NeoSansStd-Medium.eot?#iefix') format('embedded-opentype'),
        url('/fonts/subset-NeoSansStd-Medium.woff2') format('woff2'),
        url('/fonts/subset-NeoSansStd-Medium.woff') format('woff'),
        url('/fonts/subset-NeoSansStd-Medium.ttf') format('truetype'),
        url('/fonts/subset-NeoSansStd-Medium.svg#NeoSansStd-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Neo Sans Std';
    src: url('/fonts/subset-NeoSansStd-LightItalic.eot');
    src: url('/fonts/subset-NeoSansStd-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/subset-NeoSansStd-LightItalic.woff2') format('woff2'),
        url('/fonts/subset-NeoSansStd-LightItalic.woff') format('woff'),
        url('/fonts/subset-NeoSansStd-LightItalic.ttf') format('truetype'),
        url('/fonts/subset-NeoSansStd-LightItalic.svg#NeoSansStd-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'NeoSans';
    src: url('/fonts/subset-NeoSans-Bold.eot');
    src: url('/fonts/subset-NeoSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/subset-NeoSans-Bold.woff2') format('woff2'),
        url('/fonts/subset-NeoSans-Bold.woff') format('woff'),
        url('/fonts/subset-NeoSans-Bold.ttf') format('truetype'),
        url('/fonts/subset-NeoSans-Bold.svg#NeoSans-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Neo Sans Std';
    src: url('/fonts/subset-NeoSansStd-Italic.eot');
    src: url('/fonts/subset-NeoSansStd-Italic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/subset-NeoSansStd-Italic.woff2') format('woff2'),
        url('/fonts/subset-NeoSansStd-Italic.woff') format('woff'),
        url('/fonts/subset-NeoSansStd-Italic.ttf') format('truetype'),
        url('/fonts/subset-NeoSansStd-Italic.svg#NeoSansStd-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Neo Sans Std';
    src: url('/fonts/subset-NeoSansStd-BoldItalic.eot');
    src: url('/fonts/subset-NeoSansStd-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/subset-NeoSansStd-BoldItalic.woff2') format('woff2'),
        url('/fonts/subset-NeoSansStd-BoldItalic.woff') format('woff'),
        url('/fonts/subset-NeoSansStd-BoldItalic.ttf') format('truetype'),
        url('/fonts/subset-NeoSansStd-BoldItalic.svg#NeoSansStd-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Neo Sans Std';
    src: url('/fonts/subset-NeoSansStd-UltraItalic.eot');
    src: url('/fonts/subset-NeoSansStd-UltraItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/subset-NeoSansStd-UltraItalic.woff2') format('woff2'),
        url('/fonts/subset-NeoSansStd-UltraItalic.woff') format('woff'),
        url('/fonts/subset-NeoSansStd-UltraItalic.ttf') format('truetype'),
        url('/fonts/subset-NeoSansStd-UltraItalic.svg#NeoSansStd-UltraItalic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Neo Sans Std';
    src: url('/fonts/subset-NeoSansStd-Ultra.eot');
    src: url('/fonts/subset-NeoSansStd-Ultra.eot?#iefix') format('embedded-opentype'),
        url('/fonts/subset-NeoSansStd-Ultra.woff2') format('woff2'),
        url('/fonts/subset-NeoSansStd-Ultra.woff') format('woff'),
        url('/fonts/subset-NeoSansStd-Ultra.ttf') format('truetype'),
        url('/fonts/subset-NeoSansStd-Ultra.svg#NeoSansStd-Ultra') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'NeoSans';
    src: url('/fonts/subset-NeoSans-BoldItalic.eot');
    src: url('/fonts/subset-NeoSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/subset-NeoSans-BoldItalic.woff2') format('woff2'),
        url('/fonts/subset-NeoSans-BoldItalic.woff') format('woff'),
        url('/fonts/subset-NeoSans-BoldItalic.ttf') format('truetype'),
        url('/fonts/subset-NeoSans-BoldItalic.svg#NeoSans-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
}
