.header {

    $h: &;

    position: fixed;
    background: $black;
    height: 64px;
    width: 100%;
    top:0;
    z-index: 1000;

    @include mq(md) {
        height: 100vh;
        width: 230px;
        left: 0;
        padding: 50px 30px;
    }

    &__hamburguer {
        cursor: pointer;
        width: 25px;
        height: 25px;
        margin: auto;
        position: absolute;
        right: 15px;
        top: 22px;

        span {
            background-color: $white;
            border-radius: 2px;
            content: '';
            display: block;
            width: 100%;
            height: 2px;

            &:nth-child(1) {
                animation:outT 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(2) {
                margin: 7px 0;
                animation:outM 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(3) {
                animation:outBtm 0.6s $easing backwards;
                animation-direction:reverse;
            }
        }

        @include mq(md) {
            display: none;
        }
    }

    &__logo {
        height: 50px;
        overflow: hidden;
        width: 60px;
        position: absolute;
        left: 10px;
        top: 7px;

        img {
            width: 100%;
        }

        @include mq(md) {
            position: relative;
            width: 160px;
            height: 195px;
            margin: auto;
            left: 0;
            right: 0;
        }
    }

    &__nav {
        @include trans;

        position: fixed;
        top:64px;
        bottom:0;
        right:0;
        width:100%;
        background: transparentize($black, .1);
        transform: translateX(100%);
        display: flex;
        justify-content: center;
        flex-direction: column;
        color: $black;

        @include mq(md){
            position: relative;
            transform: translateX(0);
            background: $black;
            padding: 0;
            width: auto;
            display: block;
            right: 0;
            top: 100px;
            border: none;
            display: block;
        }
    }

   &__menu {

       @include mq(md){
           display: flex;

           @include mq(md){
               display: block;
               text-align: left;
               margin-bottom: 0;
           }
       }

       & > li{
           position: relative;
       }

       & > li > a{
           @include trans;

           color:$white;
           font-weight: normal;
           font-size: $xl;
           display: block;
           padding: 0 40px;
           display: block;
           margin-bottom: 30px;
           line-height: 1;
           text-transform: uppercase;


            @include mq(md){
                position: relative;
                font-size: 16px;
                letter-spacing: -0.1px;
                height: 50px;
                margin-bottom: 0;
                padding: 0;
            }

           &:hover {
               color: $pink;
           }

           &:focus {
               text-decoration: none;
           }
       }
    }

    &__social {
        display: flex;
        justify-content: space-evenly;
        width: 250px;
        margin: 100px auto 0;

        @include mq(md) {
            width: 100%;
            justify-content: space-between;
            margin-top: 150px;
        }

        a {
            display: flex;
            width: 40px;
            height: 40px;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background: $pink;
            color: $white;

            i {
                font-size: 21px;
            }

            &:hover {
                background: $pink + #222;
                color: $white;
            }
        }
    }

    &__cta {
        background: $pink;
        border-radius: $radius;
        padding: 0 40px;
        color: $white;
        height: 40px;
        line-height: 40px;
        display: block;
        position: absolute;
        top: 12px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        font-weight: 500;

        &:hover {
            background: $pink + #222;
            color: $white;
        }

        @include mq(md) {
            width: 100%;
            padding: 0;
            position: relative;
            top: -20px;
        }
    }

    &.-open {
        #{$h}__hamburguer {
            span:nth-child(1) {
                animation:inT 0.6s $easing forwards;
            }
            span:nth-child(2) {
                animation:inM 0.6s $easing forwards;
            }
            span:nth-child(3) {
                animation:inBtm 0.6s $easing forwards;
            }
        }

        #{$h}__nav {
           transform: translateX(0);
        }
    }
}

//hamburguer animations
@keyframes inM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes outM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes inT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes outT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes inBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
@keyframes outBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
